import { useCallback, useState } from "react";
import { showNotification } from "@mantine/notifications";

export enum STATUS {
  IDLE = "idle",
  PENDING = "pending",
  DONE = "done",
}

export function useFetch<Type>(method: string) {
  const [data, setData] = useState<Type | null>(null);
  const [error, setError] = useState<Error["message"] | null>(null);
  const [status, setStatus] = useState(STATUS.IDLE);

  const doFetch = useCallback(
    async (url: string, body?: object) => {
      setStatus(STATUS.PENDING);
      try {
        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
          },
          ...(body ? { body: JSON.stringify(body) } : {}),
        });

        const json = await response.json();
        setData(json);

        if (response.status !== 200) {
          throw new Error(json.message);
        }

        return json;
      } catch (err) {
        const msg = (err as Error).message;

        showNotification({
          id: msg,
          color: "red",
          title: "Oops, something went wrong.",
          message: msg,
        });

        setError(msg);
      } finally {
        setStatus(STATUS.DONE);
      }
    },
    [method]
  );

  return {
    doFetch,
    data,
    error,
    status,
    isLoading: status === STATUS.PENDING,
  };
}

export function usePost<Type>() {
  const { doFetch, data, error, status, isLoading } = useFetch<Type>("POST");

  return {
    post: doFetch,
    data,
    error,
    status,
    isLoading,
  };
}

export function usePatch<Type>() {
  const { doFetch, data, error, status, isLoading } = useFetch<Type>("PATCH");

  return {
    patch: doFetch,
    data,
    error,
    status,
    isLoading,
  };
}
