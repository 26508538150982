export const setLastVisitedWorkspace = (workspaceId: string) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("lastVisitedWorkspace", workspaceId);
  }
};

export const getLastVisitedWorkspace = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("lastVisitedWorkspace");
  }

  return null;
};

export const setLastVisitedDBInCurrentWorkspace = (
  workspaceId: string,
  dbId: string
) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(`lastVisitedDB-${workspaceId}`, dbId);
    setLastVisitedWorkspace(workspaceId);
  }
};

export const getLastVisitedDBInCurrentWorkspace = (workspaceId: string) => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(`lastVisitedDB-${workspaceId}`);
  }

  return null;
};
