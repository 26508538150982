/* eslint-disable react/display-name */
import type { UnstyledButtonProps } from "@mantine/core";
import { UnstyledButton, Box, Text } from "@mantine/core";
import { forwardRef } from "react";

export const Symbol = () => {
  return (
    <svg
      width="37"
      height="28"
      viewBox="0 0 37 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.75 13.875C27.75 20.1015 23.6486 25.3701 18 27.1265C19.3031 27.5317 20.6886 27.75 22.125 27.75C25.7942 27.75 29.1307 26.3258 31.6119 24H34.7321L33.4861 21.842C35.0701 19.5873 36 16.8398 36 13.875C36 6.21205 29.788 0 22.125 0C20.6886 0 19.3031 0.218281 18 0.623481C23.6486 2.3799 27.75 7.64848 27.75 13.875Z"
        fill="currentColor"
      />
      <circle
        cx="13.875"
        cy="13.875"
        r="12.875"
        stroke="black"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2007 20.1626C33.341 18.3392 34 16.1841 34 13.875C34 7.31662 28.6834 2 22.125 2C15.5666 2 10.25 7.31662 10.25 13.875C10.25 20.4334 15.5666 25.75 22.125 25.75C24.7479 25.75 27.1721 24.8997 29.1371 23.4596L33.7444 23.4596L32.2007 20.1626ZM22.125 27.75C25.1231 27.75 27.8991 26.7991 30.1679 25.1824L36.9052 25.1824L34.527 20.1031C35.4694 18.2302 36 16.1146 36 13.875C36 6.21205 29.788 0 22.125 0C14.462 0 8.25 6.21205 8.25 13.875C8.25 21.538 14.462 27.75 22.125 27.75Z"
        fill="black"
      />
    </svg>
  );
};

type Props = UnstyledButtonProps & {
  symbolOnly?: boolean;
};

export const Logo = forwardRef<HTMLLinkElement, Props>(
  ({ symbolOnly = false, ...props }: Props, ref: any) => {
    if (symbolOnly) {
      return (
        <UnstyledButton ref={ref} {...props}>
          <Box
            sx={(theme) => ({
              color: theme.colors.blue[4],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "4px",
            })}
          >
            <Symbol />
          </Box>
        </UnstyledButton>
      );
    }

    return (
      <UnstyledButton ref={ref} {...props}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              color: theme.colors.blue[4],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "4px",
            })}
          >
            <Symbol />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Text
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontFamily: "Arial, sans-serif",
              }}
              weight="bold"
              transform="uppercase"
            >
              raw
            </Text>
            <Text
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontFamily: "Arial, sans-serif",
              }}
              weight="bold"
              transform="uppercase"
            >
              query
            </Text>
          </Box>
        </Box>
      </UnstyledButton>
    );
  }
);
