import { useEffect } from "react";
import { getWorkspaceFromInvite } from "requests/queries";
import { setLastVisitedWorkspace } from "utils/storage";

export const useSaveWorkspaceFromInvite = (inviteid?: string) => {
  useEffect(() => {
    const getWorkspace = async () => {
      const { workspaceId } = await getWorkspaceFromInvite(inviteid as string);
      if (workspaceId) {
        setLastVisitedWorkspace(workspaceId);
      }
    };

    if (inviteid) {
      getWorkspace();
    }
  }, [inviteid]);
};
