type FetchType = {
  url: string;
  method?: string;
  body?: object;
  headers?: object;
  useAPIBaseUrl?: boolean;
};

const baseURL =
  typeof window !== "undefined"
    ? window?.location.origin
    : "http://localhost:3000";

const apiBaseUrl = process.env.NEXT_PUBLIC_API_URL;

async function doFetch<Type>({
  url,
  method,
  body,
  headers = {},
  useAPIBaseUrl = false,
}: FetchType): Promise<Type> {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${useAPIBaseUrl ? apiBaseUrl : baseURL}${url}`,
      {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        ...(body ? { body: JSON.stringify(body) } : {}),
      }
    );

    const json = await response.json();

    if (response.status !== 200) {
      reject(json.message);
    } else {
      resolve(json);
    }
  });
}

export async function get<Type>(
  url: FetchType["url"],
  headers?: object,
  useAPIBaseUrl?: boolean
): Promise<Type> {
  return doFetch<Type>({ url, method: "GET", headers, useAPIBaseUrl });
}

export async function patch<Type>(
  url: FetchType["url"],
  body: FetchType["body"],
  useAPIBaseUrl?: boolean
): Promise<Type> {
  return doFetch<Type>({ url, method: "PATCH", body, useAPIBaseUrl });
}

export async function post<Type>(
  url: FetchType["url"],
  body: FetchType["body"],
  useAPIBaseUrl?: boolean
): Promise<Type> {
  return doFetch<Type>({ url, method: "POST", body, useAPIBaseUrl });
}

export async function del<Type>(
  url: FetchType["url"],
  body: FetchType["body"],
  useAPIBaseUrl?: boolean
): Promise<Type> {
  return doFetch<Type>({ url, method: "DELETE", body, useAPIBaseUrl });
}
