import { Database, Invite, User } from "@~internal/prisma";
import {
  WorkspaceRoleWithUsers,
  WorkspaceWithDatabases,
} from "components/Shell";
import { TemplatesWithUser } from "pages/w/[workspaceid]/db/[dbid]";
import { get } from "utils/api";

export const getDatabases = (workspaceId: string) => {
  return get<Partial<Database>[]>(`/api/w/${workspaceId}/db`);
};

export const getWorkspace = (workspaceId: string) => {
  return get<Partial<WorkspaceWithDatabases>>(`/api/w/${workspaceId}`);
};

export const getWorkspaces = () => {
  return get<Partial<WorkspaceWithDatabases>[]>(`/api/w`);
};

export const getUsers = (workspaceId: string) => {
  return get<Partial<WorkspaceRoleWithUsers>[]>(`/api/w/${workspaceId}/user`);
};

export const getCurrentUser = (workspaceId: string) => {
  return get<Partial<User>>(`/api/w/${workspaceId}/user/current`);
};

export const getInvites = (workspaceId: string) => {
  return get<Partial<Invite>[]>(`/api/w/${workspaceId}/invite`);
};

export const getDBIntrospection = (workspaceId: string, dbId: string) => {
  return get<{ [key: string]: { [key: string]: string }[] }>(
    `/api/w/${workspaceId}/db/${dbId}/introspect`
  );
};

export const getTemplates = (workspaceId: string, dbId: string) => {
  return get<Partial<TemplatesWithUser>[]>(
    `/api/w/${workspaceId}/db/${dbId}/template`
  );
};

export type Usage = {
  queries: number;
  seats: number;
  databases: number;
};

export const getUsage = (workspaceId: string) => {
  return get<Partial<Usage>>(`/api/w/${workspaceId}/usage`);
};

export const getWorkspaceFromInvite = (inviteId: string) => {
  return get<Partial<Invite>>(`/api/auth/${inviteId}/workspace`);
};

export const getCurrentPlan = (workspaceId: string) => {
  return get<
    Partial<{
      id: string;
      name: string;
      price: number;
      interval: string;
      currency: number;
      status: string;
    }>
  >(`/plan?workspaceid=${workspaceId}`, undefined, true);
};

export const getValidPromoCode = (promocode: string) => {
  return get<Partial<{ validCode: string }>>(
    `/promocode?promocode=${promocode}`,
    undefined,
    true
  );
};

export const getCheckoutUrl = (
  planId: string,
  workspaceId: string,
  email: string,
  url: string
) => {
  return get<{ url: string }>(
    `/checkout?plan=${planId}&workspaceid=${workspaceId}&email=${email}&url=${url}`,
    undefined,
    true
  );
};

export const getBillingPortalUrl = (customer: string, url: string) => {
  return get<{ url: string }>(
    `/billing?customer=${customer}&url=${url}`,
    undefined,
    true
  );
};
